import { getOrCreateShopifyCart, sendShopifyBrowseWebhook, sendShopifyProductViewWebhook } from '../api';
import { identifyCustomer } from '../biz';
import { userIdentifiedEvent } from '../event';
import { promiseTry } from '../lib';

export enum SHOPIFY_PAGE_TYPE {
    PRODUCT = 'product',
    CART = 'cart',
    PAGE = 'page'
}

const getShopifyPageType = () : SHOPIFY_PAGE_TYPE | undefined => {
    const pageType = window.meta?.page?.pageType as SHOPIFY_PAGE_TYPE | undefined;

    if (pageType) {
        return pageType;
    }

    if ((/^\/products\/[^/?]+/).test(location.pathname)) {
        return SHOPIFY_PAGE_TYPE.PRODUCT;
    }

    if (location.pathname.indexOf('/cart') === 0) {
        return SHOPIFY_PAGE_TYPE.CART;
    }
};

const getSelectedProductID = () : string | undefined => {
    const productCandidate = (
        window.meta?.product?.id?.toString() ??
        window.product?.id?.toString()
    );

    const integerRegex = /^\d+$/;

    if (productCandidate && integerRegex.test(productCandidate)) {
        return productCandidate;
    }
};

export const isShopifyStore = () : boolean => {
    return Boolean(window.Shopify);
};

type TrackShopifyBrowseAndProductViewOptions = {
    accountToken : string,
};

let sentProductRecovery = false;
let sentBrowseRecovery = false;

const trackShopifyBrowseAndProductView = ({
    accountToken
} : TrackShopifyBrowseAndProductViewOptions) : Promise<void> => {
    return promiseTry(async () => {
        if (!isShopifyStore()) {
            return;
        }

        const pageType = getShopifyPageType();

        if (!pageType) {
            return;
        }

        const isEligibleProductPage = (
            pageType === SHOPIFY_PAGE_TYPE.PRODUCT ||
            pageType === SHOPIFY_PAGE_TYPE.PAGE
        );

        const canSendBrowseRecovery = (
            !sentBrowseRecovery &&
            !sentProductRecovery
        );

        const productID = getSelectedProductID();

        if (isEligibleProductPage && productID && !sentProductRecovery) {
            await sendShopifyProductViewWebhook({
                accountToken,
                productID
            });

            sentProductRecovery = true;
        } else if (Math.random() < 0.2 && canSendBrowseRecovery) {
            await sendShopifyBrowseWebhook({
                accountToken
            });

            sentBrowseRecovery = true;
        }
    });
};

export const initializeShopifyIntegration = async () : Promise<void> => {
    if (isShopifyStore()) {
        userIdentifiedEvent.listen(trackShopifyBrowseAndProductView);

        const cart = await getOrCreateShopifyCart();

        await identifyCustomer({
            cartToken: cart.token
        });
    }
};
