import { initializeCustomer } from './biz';
import { SDK_ATTRIBUTE } from './constants';
import { initializeIntegrations } from './integrations';
import {
    getSDKAuthUsername, hotReloadPageOnSDKUpdate, IntegrationError, isBot, isDevelopment,
    logger, trackError
} from './lib';

export * from './index';

const triggerOneTextLoad = () : void => {
    void logger.info('onetext_sdk_load').flush();

    const existingOneTextLoad = window.onLoadOneText ??= [];

    const load = (loader : OneTextLoader) : void => {
        setTimeout(() => {
            if (typeof loader === 'function') {
                void logger.info('onetext_child_sdk_load').flush();

                void loader();
            } else {
                logger.addPayloadBuilder(() => {
                    return {
                        childSDKName:       loader.name,
                        childSDKCommitHash: loader.commitHash
                    };
                });

                void logger.info('onetext_child_sdk_load').flush();

                if (
                    sdk.commitHash &&
                    loader.commitHash &&
                    loader.commitHash !== sdk.commitHash
                ) {
                    const err = new Error(`Child SDK commit hash mismatch. Expected ${ sdk.commitHash }, got ${ loader.commitHash }`);

                    trackError(err, {
                        type:      'child_sdk_commit_hash_mismatch',
                        hardError: false
                    });
                }

                void loader.loader();
            }
        }, 0);
    };

    window.onLoadOneText = {
        length: 0,
        push:   (...loaders) => {
            for (const loader of loaders) {
                load(loader);
            }

            return loaders.length;
        }
    };

    for (const loader of Array.from(existingOneTextLoad)) {
        load(loader);
    }
};

const initialize = async () : Promise<void> => {
    try {
        if (isBot()) {
            void logger.info('sdk_init_abort_bot').flush();
            return;
        }

        if (!getSDKAuthUsername()) {
            throw new IntegrationError({
                message: `Can not initialize SDK without ${ SDK_ATTRIBUTE.ACCOUNT_TOKEN } attribute`
            });
        }

        await Promise.all([
            triggerOneTextLoad(),
            initializeIntegrations(),
            initializeCustomer(),
            isDevelopment()
                ? hotReloadPageOnSDKUpdate()
                : undefined
        ]);
    } catch (err) {
        trackError(err, {
            type:      'sdk_init',
            hardError: false
        });

        if (err instanceof IntegrationError) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }
};

void initialize();
