import { Logger } from 'beaver-logger';

import { getStoredAccountToken } from '../biz';

import { stringifyError, UnhandledError } from './error';
import { getAPIDomain, getSDKAccountToken, getSDKAuthHeader, getSDKClientID, isDevelopment } from './sdk';

import type { JSONObject } from '@onetext/api';
import { EVENT, HTTP_HEADER } from '@onetext/api';

export let logger : ReturnType<typeof Logger>;

const ENABLE_LOGGER = isDevelopment()
    ? false
    : true;

if (ENABLE_LOGGER) {
    logger = Logger({
        url:           `${ getAPIDomain() }/api/actions`,
        flushInterval: 10 * 1000
    });

    logger.addHeaderBuilder(() => {
        const headers : { [ key in HTTP_HEADER ] ?: string } = {};

        const authHeader = getSDKAuthHeader();

        if (authHeader) {
            headers[ HTTP_HEADER.AUTHORIZATION ] = authHeader;
        }

        return headers;
    });

    logger.addPayloadBuilder(() => {
        return {
            hostAccountToken:     getSDKAccountToken(),
            hostClientToken:      getSDKClientID(),
            customerAccountToken: getStoredAccountToken(),
            sdkCommitHash:        sdk.commitHash,
            host:                 window.location.host,
            url:                  window.location.href
        };
    });
} else {
    logger = {
        debug:             () => logger,
        info:              () => logger,
        warn:              () => logger,
        error:             () => logger,
        flush:             () => Promise.resolve(),
        addHeaderBuilder:  () => logger,
        addPayloadBuilder: () => logger
    };
}

type TrackErrorOptions = {
    hardError ?: boolean,
    type : string,
    payload ?: JSONObject,
};

export const trackError = (err : unknown, {
    payload = {},
    hardError = err instanceof UnhandledError
        ? err.hardError
        : true,
    type
} : TrackErrorOptions) : void => {
    const eventName = hardError
        ? EVENT.CLIENT_UNHANDLED_ERROR
        : EVENT.CLIENT_SOFT_UNHANDLED_ERROR;

    void logger.error(eventName, {
        ...payload,
        error:     stringifyError(err),
        type,
        hardError,
        errorData: err instanceof UnhandledError
            ? err.data
            : undefined
    }).flush();
};
